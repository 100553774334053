
import {
  defineComponent,
  reactive,
  ref,
  toRaw,
  onMounted,
  computed,
  getCurrentInstance
} from 'vue'
import { useStore } from '@/store'
import { User } from '@/store/login/types'
import hhRequest from '@/service'
import Avatar from './avatar.vue'

interface DataItem {
  code: string
  name: string
}

interface Data {
  countries: DataItem[]
  identities: DataItem[]
}

export default defineComponent({
  name: 'PersonalInfoEdit',
  components: { Avatar },
  setup() {
    const {
      state: { login },
      commit
    } = useStore()
    const store = useStore()
    const user = login.user
    const form = ref()

    store.dispatch('profile/getCountriesDataAction')
    store.dispatch('profile/getIdentitiesDataAction')

    const countries = computed(() => store.getters['profile/getCountriesData'])
    const identities = computed(
      () => store.getters['profile/getIdentitiesData']
    )

    const basic = reactive({
      countries: countries,
      identities: identities
    })

    const state = reactive<User>({ ...user! })

    const proxy = getCurrentInstance()
    const globalProperties = proxy?.appContext.config.globalProperties
    const rules = {
      name: {
        firstName: {
          required: true,
          message: globalProperties?.$filters.hh_t('firstNameNotEmpty')
        },
        lastName: {
          required: true,
          message: globalProperties?.$filters.hh_t('lastNameNotEmpty')
        }
      },
      birthday: {
        required: true,
        message: globalProperties?.$filters.hh_t('birthdayNotEmpty')
      }
    }

    const submit = async () => {
      try {
        if (form.value) {
          await form.value.validate()
          const user = toRaw(state)
          await hhRequest.post({ url: '/user/info', data: user })

          store.commit('login/setUser', user)
        }
      } catch (err) {
        return err
      }
    }

    // 将日历的图标从左边换到右边
    onMounted(() => {
      if (document.getElementsByClassName('datePicker')) {
        document.getElementsByClassName(
          'el-input__suffix-inner'
        )[0].className += ' el-icon-date'
      }
    })

    return {
      form,
      state,
      rules,
      basic,
      submit
    }
  }
})
